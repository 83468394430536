<template>
  <div class="personnel">
    <PageTop
      :tabsShow="true"
      @addMeans="addMeans"
      @searchPersonnel="searchPersonnel"
      :personnelShow="true"
    >
      <div class="adTitle" slot="adTitle">人员管理</div>
      <p slot="addName">添加员工</p>
    </PageTop>
    <div class="mains">
      <div class="lists" v-if="perList.length">
        <div
          class="items animated fadeInUp"
          :class="item.mtype === 3 ? 'active1' : 'active2'"
          v-for="(item, index) in perList"
          :key="item.id"
        >
          <div class="infos">
            <div class="infos-R">
              <img
                class="portrait"
                :src="item.photo ? uploadImgs + item.photo : heads"
              />
              <div class="text">
                <p class="name">{{ item.name }}</p>
                <p class="phone">{{ item.phone }}</p>
              </div>
            </div>
            <div class="infos-L">
              <img
                class="img-icon"
                src="../assets/image/personnel/icon1.png"
                @click="editPro(item.id)"
              />
              <img
                class="img-icon"
                src="../assets/image/personnel/icon6.png"
                v-show="!item.custom_state"
                @click="enableProState(item.id, index)"
              />
              <img
                class="img-icon"
                src="../assets/image/personnel/icon5.png"
                v-show="item.custom_state"
                @click="disableProState(item.id, index)"
              />
              <img
                class="img-icon"
                src="../assets/image/personnel/icon2.png"
                @click="disablePro(item.id, index)"
              />
            </div>
          </div>
          <div class="tags">
            <div class="tagItems one-txt-cut">
              {{
                item.mtype === 1
                  ? "农场主"
                  : item.mtype === 2
                  ? "管理员"
                  : "员工"
              }}
            </div>
            <div class="tagItems one-txt-cut">{{ item.number }}</div>
            <!-- <div class="tagItems one-txt-cut">1号农场</div> -->
          </div>
        </div>
      </div>
      <div class="lists" v-else>
        <MyNothing></MyNothing>
      </div>
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        @current-change="handleCurrentChange"
        v-if="perList.length"
      >
      </el-pagination>
    </div>
    <MyNav></MyNav>
    <el-dialog
      class="big-pop"
      title=" "
      :visible.sync="addShow"
      width="753px"
      :before-close="setAddClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @open="openDialog"
    >
      <div class="big-main">
        <div class="ware-title">
          <div class="left-line"></div>
          {{ PopTitle }}
        </div>
        <div class="add-form">
          <el-form
            ref="addForm"
            :rules="rules"
            :model="form"
            hide-required-asterisk
          >
            <el-form-item
              label="姓名"
              :label-width="formLabelWidth"
              prop="name"
              key="name"
            >
              <el-input
                v-model.trim="form.name"
                autocomplete="off"
                placeholder="请输入姓名"
                maxlength="20"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="员工号"
              :label-width="formLabelWidth"
              prop="number"
              key="number"
            >
              <el-input
                v-model.trim="form.number"
                autocomplete="off"
                placeholder="请输入员工号"
                maxlength="20"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="电话"
              v-model="form.phone"
              :label-width="formLabelWidth"
              prop="phone"
              key="phone"
            >
              <el-input
                v-model.trim="form.phone"
                autocomplete="off"
                placeholder="请输入电话"
                maxlength="20"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="类型"
              :label-width="formLabelWidth"
              prop="mtype"
              key="mtype"
            >
              <el-radio-group v-model="form.mtype">
                <!-- <el-radio :label="1" v-model="form.mtype">农场主</el-radio> -->
                <el-radio :label="2">管理员</el-radio>
                <el-radio :label="3">工人</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
          <div class="uploads">
            <div class="preview">
              <img class="imgs" v-if="imageUrl" :src="uploadImgs + imageUrl" />
              <img
                v-else
                class="imgs"
                src="../assets/image/personnel/icon4.png"
              />
            </div>
            <el-upload
              class="upload-demo"
              action="https://resource.darsing.net/api/v1/img"
              name="img"
              :headers="uploadHeaders"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :on-error="handleAvatarFail"
            >
              <el-button size="small" type="primary" class="op-btn"
                >点击上传</el-button
              >
            </el-upload>
          </div>
        </div>
        <div class="dialog-footer">
          <el-button class="ok-button" type="primary" @click="okAdd('addForm')"
            >确 定</el-button
          >
          <el-button class="no-button" @click="noAdd('addForm')"
            >取 消</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  showFullScreenLoading,
  tryHideFullScreenLoading,
} from "@/utils/screenLoading";
import {
  getMemberListApi,
  getMemberInfoApi,
  postEditMemberInfoApi,
  postAddMemberApi,
  delMemberApi,
  postEditMemberStateApi,
} from "@/request/api";
import { mapState } from "vuex";

export default {
  components: {
    MyNav: () => import("@/components/MyNav.vue"),
    PageTop: () => import("@/components/PageTop.vue"),
    MyNothing: () => import("@/components/MyNothing.vue"),
  },
  data() {
    return {
      // 分页
      total: 20, // 总数
      last_page: 1, //末尾页
      per_page: 10, // 一页个数
      current_page: 1, // 当前页
      // 人员列表
      perList: [],
      // 人员ID
      id: "",
      heads: require("../assets/image/personnel/icon3.png"),
      PopTitle: "表单信息",
      //   添加/编辑 弹窗
      addShow: false,
      // 人员详情表单信息
      form: {
        name: "",
        number: "",
        phone: "",
        mtype: "",
      },
      // 搜索
      title: undefined,
      mtype: undefined,
      // 表单校验规则
      rules: {
        name: [
          {
            required: true,
            whitespace: true,
            message: "请输入姓名",
            trigger: "blur",
          },
        ],
        number: [
          {
            required: true,
            whitespace: true,
            message: "请输入工号",
            trigger: "blur",
          },
        ],
        phone: {
          required: true,
          pattern:
            /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
          message: "手机格式错误",
          trigger: "blur",
        },
        mtype: [{ required: true, message: "请选择类型", trigger: "change" }],
      },
      formLabelWidth: "120px",
      // 上传
      imageUrl: "",
      uploadHeaders: {
        Authorization: localStorage.getItem("darsingToken"),
      },
      member_id: "",
    };
  },
  computed: {
    ...mapState({
      farmID: (state) => state.user.farmID,
      token: (state) => state.user.token,
      uploadImgs: (state) => state.app.uploadImgs,
    }),
  },
  created() {
    this.getMemberListApi();
  },
  methods: {
    // 切换分页
    handleCurrentChange(val) {
      this.current_page = val;
      this.getMemberListApi();
    },
    // 启用地块
    enableProState(id, index) {
      this.id = id;
      this.postEditMemberStateApi(index, 1);
      this.$notify({
        title: "成功",
        message: "已启用",
        type: "success",
      });
    },
    // 禁用
    disableProState(id, index) {
      this.id = id;
      this.$confirm("此操作将禁用该人员, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.postEditMemberStateApi(index, 0);
          this.$notify({
            title: "成功",
            message: "已禁用",
            type: "success",
          });
        })
        .catch(() => {
          this.$notify({
            title: "提示",
            message: "已取消操作",
          });
        });
    },
    openDialog() {
      this.$nextTick(() => {
        this.$refs.addForm.clearValidate();
      });
    },
    //修改人员状态
    async postEditMemberStateApi(index, status) {
      const { code } = await postEditMemberStateApi({
        member_id: this.id,
        custom_state: status,
      });
      if (code === 0) {
        this.perList[index].custom_state = !this.perList[index].custom_state;
        this.$forceUpdate();
      }
    },
    // 搜索
    searchPersonnel({ name, type }) {
      this.title = name ? name : undefined;
      this.mtype = +type ? +type : undefined;
      this.current_page = 1;
      this.getMemberListApi();
    },
    // 删除员工
    async delMemberApi() {
      const { code } = await delMemberApi({
        member_id: this.member_id,
      });
      if (code === 0) {
        // this.getMemberListApi()
        this.$notify({
          title: "成功",
          message: "已删除",
          type: "success",
        });
      }
    },
    // 新增员工
    async postAddMemberApi() {
      const { code } = await postAddMemberApi({
        ...this.form,
        manor_id: this.farmID,
        photo: this.imageUrl,
      });
      if (code === 0) {
        this.getMemberListApi();
        this.$notify({
          title: "成功",
          message: "已添加",
          type: "success",
        });
        this.addShow = false;
        this.imageUrl = "";
        this.form = {
          name: "",
          number: "",
          phone: "",
          mtype: "",
        };
      }
    },
    // 修改人员信息
    async postEditMemberInfoApi() {
      const { code } = await postEditMemberInfoApi({
        ...this.form,
        member_id: this.member_id,
        photo: this.imageUrl,
      });
      if (code === 0) {
        this.getMemberListApi();
        this.$notify({
          title: "成功",
          message: "已修改",
          type: "success",
        });
        this.addShow = false;
        this.form = {
          name: "",
          phone: "",
          number: "",
          mtype: "",
          photo: "",
          uid: "",
        };
      }
    },
    // 获取员工详情
    async getMemberInfoApi(member_id) {
      const { code, results } = await getMemberInfoApi({
        member_id,
      });
      if (code === 0) {
        this.form = results;
        this.imageUrl = results.photo;
      }
    },
    async getMemberListApi() {
      showFullScreenLoading();
      const { code, results } = await getMemberListApi({
        manor_id: this.farmID,
        pn: this.current_page,
        name: this.title,
        mtype: this.mtype,
      });
      if (code === 0) {
        this.total = results.count;
        this.per_page = results.ps;
        this.current_page = results.pn;
        this.perList = results.data;
      }
      tryHideFullScreenLoading();
    },
    // 上传成功
    handleAvatarSuccess(res, file) {
      if (file.percentage === 100) {
        this.imageUrl = file.response.Data.imgid;
        this.$notify({
          title: "成功",
          message: "上传成功",
          type: "success",
        });
      }
    },
    // 上传失败
    handleAvatarFail() {
      this.$notify.error({
        title: "错误",
        message: "上传失败，稍后尝试",
      });
    },
    // 编辑按钮
    editPro(id) {
      this.member_id = id;
      this.getMemberInfoApi(id);
      this.PopTitle = "编辑员工";
      this.addShow = true;
    },
    EnableMeans() {
      this.$notify({
        title: "成功",
        message: "已启用",
        type: "success",
      });
    },
    disablePro(id, index) {
      this.member_id = id;
      this.$confirm("此操作将删除该员工, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.perList.splice(index, 1);
          if (!this.perList.length) {
            this.current_page = 1;
          }
          this.delMemberApi();
        })
        .catch(() => {
          this.$notify({
            title: "提示",
            message: "已取消操作",
          });
        });
    },
    noAdd(formName) {
      this.addShow = false;
      this.$refs[formName].resetFields();
      this.imageUrl = "";
      this.form = {
        name: "",
        number: "",
        phone: "",
        mtype: "",
      };
    },
    okAdd(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) return;
        if (this.PopTitle === "添加员工") {
          this.postAddMemberApi();
        } else {
          this.postEditMemberInfoApi();
        }
      });
    },
    addMeans() {
      this.PopTitle = "添加员工";
      this.addShow = true;
    },
    setAddClose() {
      this.addShow = false;
      this.imageUrl = "";
      this.form = {
        name: "",
        number: "",
        phone: "",
        mtype: "",
      };
    },
  },
};
</script>

<style lang="less" scoped>
.personnel {
  width: 100%;
  min-height: 100vh;
  background-color: #071a2c;
  padding: 62px 48px 0;

  .mains {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    background-color: #0e2138;
    padding: 22px 48px;

    .lists {
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      .items {
        width: 326px;
        height: 149px;
        border-radius: 8px;
        margin: 0 20px 39px 0;
        display: flex;
        flex-direction: column;
        padding: 18px;
        justify-content: space-between;
        &:hover .infos .infos-L {
          display: block;
        }

        &:nth-of-type(5n) {
          margin-right: 0;
        }

        &.active1 {
          background: url(../assets/image/personnel/bgc1.png) no-repeat 0 0;
          background-size: cover;
        }

        &.active2 {
          background: url(../assets/image/personnel/bgc2.png) no-repeat 0 0;
          background-size: cover;
        }

        .infos {
          width: 100%;
          // margin-bottom: 17px;
          display: flex;
          justify-content: space-between;

          .infos-R {
            flex: 1;
            display: flex;
            align-items: center;

            .portrait {
              width: 68px;
              height: 68px;
              border-radius: 15px;
              margin-right: 13px;
            }

            .text {
              color: #fff;

              .name {
                font-size: 18px;
                font-weight: bold;
                margin-bottom: 12px;
              }

              .phone {
                font-size: 14px;
              }
            }
          }

          .infos-L {
            display: none;

            .img-icon {
              width: 30px;
              height: 30px;
              margin-right: 8px;
              cursor: pointer;

              &:last-of-type {
                margin-right: 0;
              }
            }
          }
        }

        .tags {
          width: 100%;
          display: flex;
          align-items: center;

          .tagItems {
            background-color: rgba(0, 0, 0, 0.2);
            height: 25px;
            max-width: 116px;
            border-radius: 13px;
            line-height: 25px;
            text-align: center;
            color: #fff;
            font-size: 14px;
            margin-right: 10px;
            padding: 0 15px;

            &:last-of-type {
              margin-right: 0;
            }
          }
        }
      }
    }
  }

  .big-pop {
    /deep/.el-dialog {
      width: 753px !important;
      background-color: inherit;
    }

    /deep/.el-dialog__body {
      padding: 0;
    }

    /deep/.el-dialog__headerbtn {
      font-size: 26px;
      top: 64px;
      right: 50px;
      font-weight: bold;
    }

    /deep/.el-button span {
      color: #fff;
    }

    /deep/.el-dialog__headerbtn {
      top: 71px;
      right: 44px;
    }

    /deep/.dialog-footer {
      padding: 0 0 0 119px;

      .el-form-item__content {
        width: 353px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    .ok-button {
      width: 150px;
      height: 40px;
      margin-right: 54px;
    }

    .no-button {
      width: 150px;
      height: 40px;
      border: 1px solid #385576;
      background-color: #0f243d;
    }

    .big-main {
      width: 753px;
      height: 521px;
      background: url(../assets/image/personnel/popBgB.png) no-repeat center
        center;
      background-size: cover;
      padding: 45px 55px 0 55px;
      .ware-title {
        font-size: 22px;
        color: #fff;
        font-weight: bold;
        display: flex;
        align-items: center;
        margin-bottom: 40px;

        .left-line {
          background-color: #fff;
          width: 4px;
          height: 21px;
          border-radius: 3px;
          margin-right: 10px;
        }
      }

      .add-form {
        display: flex;
        justify-content: space-between;

        .uploads {
          display: flex;
          flex-direction: column;
          align-items: center;

          .preview {
            width: 127px;
            height: 127px;
            background: #081c30;
            border: 1px solid #385576;
            border-radius: 3px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 17px;
            position: relative;
            &.active {
              border-color: #f56c6c;
            }
            .imgs {
              width: 68px;
              height: 68px;
            }
          }

          .op-btn {
            width: 91px;
            height: 23px;
            background: #3e90e5;
            font-size: 14px;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 !important;
          }
        }
      }

      /deep/.el-input__inner {
        background-color: #081c30;
        border: 1px solid #385576;
        color: #57708d;
        width: 282px;
      }

      /deep/.el-form-item__label {
        font-size: 14px;
        color: #fff;
        padding: 0 24px 0 0;
      }

      /deep/.el-radio__input.is-checked + .el-radio__label {
        color: #fff;
      }

      /deep/.el-radio {
        color: #fff;
      }
    }
  }
}
</style>
